import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';

import Link from '../Links';

import './logo.css';
import { LogoPlaceholder } from './placeholders';

export default function Logo() {
  const data = useStaticQuery(graphql`
    query LogoImage {
      wp {
        ...SiteName
        ...SiteLogo
      }
    }
  `);
  const siteName = data.wp.generalSettings.title;
  const logo = data.wp.options.websiteOptions.general.logo.localFile.publicURL;

  const [svg, setSvg] = useState(false);

  useEffect(() => {
    fetch(logo)
      .then(res => res.text())
      .then(setSvg);
  }, []);

  return (
    <Link to="/" label={siteName}>
      <div
        className="logo"
        style={{
          maxWidth: `100%`,
          lineHeight: 0,
          transition: `all 0.3s linear`,
        }}
      >
        {svg ? parse(svg) : <LogoPlaceholder />}
      </div>
    </Link>
  );
}
